import React, { FunctionComponent } from 'react'

type Props = {
  vehicle: any
  keys: { key: string; value: string; convert?: any }[]
}

export const VehicleInfo: FunctionComponent<Props> = ({ vehicle, keys }) => {
  return (
    <table className="border-collapse w-full table-fixed">
      {keys.map(({ key, value, convert }) => (
        <tr key={key}>
          <th className="text-left">{key}</th>
          <td>{convert ? convert[vehicle[value]] : vehicle[value]}</td>
        </tr>
      ))}
    </table>
  )
}
