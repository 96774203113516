import React, { FunctionComponent } from 'react'
import { OrderLines } from './OrderLines'

type Props = {
  history: any[]
}

export const VehicleHistory: FunctionComponent<Props> = ({ history }) => {
  return (
    <div className="flex flex-col gap-6">
      {history.length === 0 ? (
        <div>Vi fant dessverre ingen historikk på dette kjøretøyet.</div>
      ) : (
        history.map((h) => (
          <div>
            <h3 className="text-2xl">
              Fakturadato {new Date(h.invoice_date).toLocaleDateString()}
            </h3>
            <table className="table-fixed w-full text-left">
              <tr className="border-b">
                <th className="py-2">Faktura nr.</th>
                <td>{h.invoice_no}</td>
              </tr>
              <tr className="border-b">
                <th className="py-2">Kilometerstand</th>
                <td>{h.mileage}</td>
              </tr>
              <tr className="border-b">
                <th className="py-2">Ordrelinjer</th>
                <td>
                  <OrderLines text={h.text} />
                </td>
              </tr>
              <tr className="border-b">
                <th className="py-2">Verksted</th>
                <td>{h.workshop_name}</td>
              </tr>
            </table>
          </div>
        ))
      )}
    </div>
  )
}
