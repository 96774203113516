export const InfoBox = () => {
  return (
    <div className="flex flex-col p-8">
      <h2 className="text-xl mb-4 text-center">Digitalt Servicehefte:</h2>
      <p className="text-center mb-6">
        Det digitale servicehefte inneholder alle opplysninger om hva som er
        gjort med et konkret kjøretøy. Her finner du servicer og alt annet som
        er utført på dette kjøretøyet utført hos et BilXtra verksted. I tillegg
        vil du kunne se diverse teknisk informasjon om din bil.
      </p>

      <h3 className="text-lg mb-4 text-center">Fordeler med digitalt: </h3>
      <ul className="list-disc self-center">
        <li>Papirhefter finnes nesten ikke i nye biler</li>
        <li>Digitalt inneholder også andre jobber i tillegg til service </li>
        <li>Ved kjøp og salg av kjøretøy Ved reklamasjon</li>
        <li>Ved dokumentasjon mot andre aktører</li>{' '}
        <li>Felles historikk ved bruk av flere Bilxtra verksted</li>
        <li>Full historikk hele døgnet og uansett hvor du er</li>
        <li>Tilgjengelig for andre Bilxtra verksted</li>
      </ul>
    </div>
  )
}
