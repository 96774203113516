import { FunctionComponent, useState } from 'react'
import flag from '../images/no-flag.png'

type Props = {
  search: (regno: string) => void
}

export const VehicleSearch: FunctionComponent<Props> = ({ search }) => {
  const [regno, setRegno] = useState<string>('')

  return (
    <form
      className="flex border-4 border-primary-dark w-full"
      onSubmit={(e) => {
        e.preventDefault()
        search(regno)
      }}
    >
      <div className="flex flex-col align-center justify-center bg-primary text-center py-6 px-8">
        <img className="h-6" src={flag} alt="Norsk flagg" />
        <span className="text-white text-xl">N</span>
      </div>
      <input
        type="input"
        className="text-4xl py-4 px-6 flex-1"
        value={regno}
        autoFocus
        onChange={({ target }) => setRegno(target.value.toUpperCase())}
        placeholder="REGISTRERINGSNUMMER"
      />
      <button
        type="submit"
        className="bg-primary text-white text-4xl py-6 px-8"
        disabled={!regno}
      >
        Søk
      </button>
    </form>
  )
}
