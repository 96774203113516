import React, { FunctionComponent, useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useMeasure } from 'react-use'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const defaultHeight = '1px'

type Props = {
  text: string
}

export const OrderLines: FunctionComponent<Props> = ({ text }) => {
  const [open, toggle] = useState(false)
  const [contentHeight, setContentHeight] = useState<any>(defaultHeight)
  const [ref, { height }] = useMeasure()

  const expand = useSpring({
    config: { friction: 20 },
    height: open ? `${contentHeight}px` : defaultHeight,
  })

  const spin = useSpring({
    config: { friction: 20 },
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  })

  useEffect(() => {
    setContentHeight(height)

    // @ts-ignore
    window.addEventListener('resize', () => setContentHeight(height))
    // @ts-ignore
    return window.removeEventListener('resize', () => setContentHeight(height))
  }, [height])

  return (
    <React.Fragment>
      <animated.div style={expand}>
        {open && (
          /*// @ts-ignore*/
          <div ref={ref}>
            {text.replace('[', '').replace(']', '').split(',')}
          </div>
        )}
      </animated.div>
      <button
        type="button"
        className="bg-primary text-white px-4 py-2 flex gap-4 items-center"
        onClick={() => toggle(!open)}
      >
        {open ? 'Skjul ordrelinjer' : 'Vis ordrelinjer'}
        <animated.div style={spin}>
          <FontAwesomeIcon icon={faChevronDown} />
        </animated.div>
      </button>
    </React.Fragment>
  )
}
