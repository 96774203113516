import React, { FunctionComponent } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import car from '../animations/driving-car.json'
import { animated, useSpring } from 'react-spring'

type Props = {
  show: boolean
}

export const CarLoader: FunctionComponent<Props> = ({ show }) => {
  const styles = useSpring({ opacity: show ? 1 : 0 })

  return (
    <animated.div
      className="absolute"
      style={{
        ...styles,
        zIndex: 0,
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <Player autoplay loop src={car} style={{ height: '250px' }} />
    </animated.div>
  )
}
