import carsLogo from '../images/cars_logo-3.png'
import bilxtraLogo from '../images/Bilxtra_verksted_logo_01.png'

export const Header = () => {
  return (
    <header className="h-20 bg-white">
      <div
        className="flex items-center justify-between mx-auto py-4"
        style={{ maxWidth: '1200px' }}
      >
        <div className="text-4xl">Digital Servicehefte</div>
        <div className="flex items-center">
          <img className="h-10" src={carsLogo} alt="Cars Software" />
          <img className="h-10" src={bilxtraLogo} alt="Bilxtra verksted" />
        </div>
      </div>
    </header>
  )
}
