export function fetchData(url: string) {
  const isOk = (response: Response) => {
    if (response.ok) {
      return response
    }

    throw new Error('Unable to fetch vehicle data')
  }

  const handleResponse = (response: Response) => response.json()

  return fetch(url).then(isOk).then(handleResponse)
}
