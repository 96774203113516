import React, { useState } from 'react'
import { Header } from './components/Header'
import { VehicleSearch } from './components/VehicleSearch'
import { InfoBox } from './components/InfoBox'
import { API_URL } from './environment'
import { fetchData } from './fetch'
import { CarLoader } from './components/CarLoader'
import { Vehicle } from './components/vehicle/Vehicle'

function App() {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [vehicle, setVehicle] = useState<any>()
  const [history, setHistory] = useState<any>()

  const getVehicle = (regno: string) => {
    setStatus('pending')

    Promise.all([
      fetchData(`${API_URL}vehicle/${regno}`),
      fetchData(`${API_URL}history/${regno}`),
    ])
      .then(([vehicle, { history }]) => {
        setVehicle(vehicle)
        setHistory(history)
        setStatus('idle')
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <div className="flex-1 flex flex-col bg-workshop">
      <Header />

      <div
        className="flex flex-col gap-10 self-center justify-center mt-20 w-full mx-auto"
        style={{ maxWidth: '960px' }}
      >
        <VehicleSearch search={getVehicle} />

        <div
          className="relative bg-white flex flex-col"
          style={{ minHeight: '20rem' }}
        >
          <CarLoader show={status === 'pending'} />

          {status === 'idle' && !vehicle && <InfoBox />}

          <div className="relative z-10 flex items-center h-full justify-center">
            {status === 'failure' && (
              <div className="text-red-600 font-bold text-center">
                Det oppstod dessverre en feil.
              </div>
            )}

            {status === 'idle' && vehicle && (
              <Vehicle vehicle={vehicle} history={history} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
