import React, { FunctionComponent, useState } from 'react'
import { VehicleTabs } from './VehicleTabs'
import { VehicleInfo } from './VehicleInfo'
import { VehicleHistory } from './VehicleHistory'

type Props = {
  vehicle: any
  history: any
}

export const Vehicle: FunctionComponent<Props> = ({ vehicle, history }) => {
  const [selectedTab, setSelectedTab] = useState('Bil info')

  const infoKeys = [
    { key: 'Merke', value: 'merkeNavn' },
    { key: 'Modell', value: 'modellbetegnelse' },
    { key: 'Årsmodell', value: 'regAAr' },
    { key: '1. gang. reg.', value: 'regFgang' },
    { key: '1. gang. reg. Norge', value: 'regFoerstegNorge' },
    {
      key: 'Bruktimportert',
      value: 'bruktimport',
      convert: { 0: 'Nei', 1: 'Ja' },
    },
    { key: 'Biltype', value: 'typebetegnelse' },
    { key: 'Drivstoff', value: 'drivstoff' },
    { key: 'Girkasse', value: 'girkasse' },
  ]

  const technicalInfoKeys = [
    { key: 'Lengde', value: 'lengde' },
    { key: 'Bredde', value: 'bredde' },
    { key: 'Motornummer', value: 'motormerking' },
    { key: 'Dekk dim foran', value: 'stdDekkForan' },
    { key: 'Dekk dim bak', value: 'stdDekkBak' },
    { key: 'Std. felg bak', value: 'stdFelgBak' },
    { key: 'Std. felg foran', value: 'stdFelgForan' },
    { key: 'Aksler m/drift', value: 'aksler_drift' },
    { key: 'Egenvekt', value: 'egenvekt' },
    { key: 'Totalvekt', value: 'totalvekt' },
  ]

  return (
    <div className="flex flex-col">
      <VehicleTabs selectedTab={selectedTab} onSelectTab={setSelectedTab} />

      <div className="p-6">
        {selectedTab === 'Bil info' ? (
          <VehicleInfo vehicle={vehicle} keys={infoKeys} />
        ) : selectedTab === 'Teknisk' ? (
          <VehicleInfo vehicle={vehicle} keys={technicalInfoKeys} />
        ) : selectedTab === 'Historikk' ? (
          <VehicleHistory history={history} />
        ) : null}
      </div>
    </div>
  )
}
