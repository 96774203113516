import React, { FunctionComponent } from 'react'

type Props = {
  selectedTab: string
  onSelectTab: (selected: string) => void
}

export const VehicleTabs: FunctionComponent<Props> = ({
  selectedTab,
  onSelectTab,
}) => {
  const buttons = ['Bil info', 'Teknisk', 'Historikk']

  return (
    <div>
      <div className="flex align-center justify-between">
        {buttons.map((button) => {
          const defaultClassNames = 'w-full py-4'

          const isSelected = selectedTab === button
          const classNames = isSelected
            ? defaultClassNames + ' bg-primary text-white'
            : defaultClassNames

          return (
            <button
              key={button}
              className={classNames}
              onClick={() => onSelectTab(button)}
            >
              {button}
            </button>
          )
        })}
      </div>
    </div>
  )
}
